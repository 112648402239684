import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo"; 
import { Link, navigate } from "gatsby";
import { isAuthenticated} from "../components/utils/auth"


function IndexPage() {
  if (isAuthenticated()) {
    navigate(`/guests/start`)
    return null
  }
  return (
    <div>
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Start"
      />
      <section id="Start" className="text-center">
        <h1 className="md:text-8xl text-4xl text-primary_dark font-semibold font-sawarabi-mincho m-10 p-10">Willkommen auf der Seite zu unserer Hochzeit</h1>
        {/* <h1 className="md:text-8xl text-4xl text-primary_dark font-semibold font-sawarabi-mincho m-10 p-10">Die Seite ist demnächst fertig</h1> */}
        <Link to="/guests/login" id="signin-btn" className="text-lg py-2 px-4 mt-1 mb-1 ml-2 mr-2 rounded bg-primary hover:bg-primary_dark transition duration-300" title="Anmelden">Anmelden</Link>
      </section>
    </Layout>
    </div>
  );
}

export default IndexPage;
